import { NgComponentOutlet, NgIf } from '@angular/common';
import { Component, Injector, Input, OnInit, Type } from '@angular/core';
import { SidebarService } from '../../services/sidebar.service';
import { sidebars, SidebarType } from './sidebar-list';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [NgIf, NgComponentOutlet],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent implements OnInit {
  @Input() type: SidebarType;

  // is true after open-animation is finished
  isCompletelyOpened = false;

  loadedComponent: Type<any> | null = null;
  loadedComponentInjector: Injector | null = null;

  constructor(
    private injector: Injector,
    public sidebarService: SidebarService
  ) {}

  handleClickOutside($event: MouseEvent) {
    $event.stopPropagation();

    if (
      this.sidebarService.isSidebarOpen(this.type) &&
      this.isCompletelyOpened
    ) {
      this.isCompletelyOpened = false;
      this.sidebarService.closeSidebar({ type: this.type, action: 'cancel' });
    }
  }

  loadComponent() {
    return this.findSidebar(this.type)?.loadComponent();
  }

  findSidebar(type: SidebarType) {
    return sidebars.find((sidebar) => sidebar.type === type);
  }

  getTitle() {
    return this.findSidebar(this.type)?.title ?? '';
  }

  async ngOnInit() {
    const sidebarInfo = this.findSidebar(this.type);
    if (sidebarInfo) {
      const componentClass = await sidebarInfo.loadComponent();
      this.loadedComponent = componentClass;
      const sidebar = this.sidebarService.getSidebar(this.type);
      this.loadedComponentInjector = Injector.create({
        providers: [
          { provide: 'sidebarProps', useValue: sidebar?.props ?? null },
        ],
        parent: this.injector,
      });

      setTimeout(() => {
        this.isCompletelyOpened = true;
      }, 300);
    }
  }
}

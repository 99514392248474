import { OrderFullSizeDto } from "../../../../../shared/dtos";

export type SidebarTypeMap = {
  orderBasket: {
    props: {
      order: OrderFullSizeDto;
    }
    sidebarResult: undefined;
  },

  // Example of a sidebar with no props and a result
  secondSidebar: {
    props: null,
    sidebarResult: undefined;
  }
};

export type SidebarType = keyof SidebarTypeMap;

export class SidebarComponent {
  type: SidebarType;
  title: string;
  loadComponent: () => any;
}

export class Sidebar<T extends SidebarType> {
  closing = false;
  constructor(public readonly sidebarType: T, public readonly props: SidebarTypeMap[T]['props']) {}
}

export interface SidebarOpenEvent<T extends SidebarType> {
  type: T;
  props?: SidebarTypeMap[T]['props'];
}

export interface SidebarUpdateEvent<T extends SidebarType> {
  type: T;
  backButtonActive: boolean,
}

export interface SidebarCloseEvent<T extends SidebarType> {
  type: T;
  action: "cancel" | "confirm";
  sidebarResult?: SidebarTypeMap[T]['sidebarResult'];
}

export const sidebars: SidebarComponent[] = [
  {
    type: "orderBasket",
    title: "Warenkorb",
    loadComponent: () => import('../../order/order-basket/order-basket.component').then(c => c.OrderBasketComponent),
  },
];

import { Injectable } from '@angular/core';
import { filter, Observable, Subject } from 'rxjs';
import {
  Sidebar,
  SidebarCloseEvent,
  SidebarOpenEvent,
  SidebarType,
} from '../generic/sidebar/sidebar-list';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private sidebarStack: Sidebar<SidebarType>[] = [];
  private openTrigger: Subject<any> = new Subject<any>();
  private closeTrigger: Subject<any> = new Subject<any>();

  constructor() {
    this.openTrigger.subscribe(() => {
      this.setBodyTags();
    });
    this.closeTrigger.subscribe(() => {

      // sync with closing animation
      setTimeout(() => {
        this.setBodyTags();
      }, 200);
    });
  }

  setBodyTags() {
    const anySidebarOpen = this.anySidebarOpen();

    document.getElementsByTagName('body')[0].style.position = anySidebarOpen
      ? 'fixed'
      : 'relative';
    document.getElementsByTagName('body')[0].style.top = anySidebarOpen
      ? '0'
      : 'inherit';
    document.getElementsByTagName('body')[0].style.left = anySidebarOpen
      ? '0'
      : 'inherit';
    document.getElementsByTagName('body')[0].style.right = anySidebarOpen
      ? '0'
      : 'inherit';
    document.getElementsByTagName('body')[0].style.bottom = anySidebarOpen
      ? '0'
      : 'inherit';
  }

  getSidebar(sidebarType: SidebarType) {
    return this.sidebarStack.find((s) => s.sidebarType === sidebarType);
  }

  getAllSidebars() {
    return this.sidebarStack;
  }

  isSidebarOpen(sidebarType: SidebarType) {
    return this.sidebarStack
      .filter((s) => !s.closing)
      .map((s) => s.sidebarType)
      .includes(sidebarType);
  }

  anySidebarOpen() {
    return this.sidebarStack.length > 0;
  }

  openSidebar<T extends SidebarType>(event: SidebarOpenEvent<T>) {
    const sidebar = new Sidebar<T>(event.type, event.props || null);
    this.sidebarStack.push(sidebar);
    this.openTrigger.next(event);
  }

  closeSidebar<T extends SidebarType>(event: SidebarCloseEvent<T>) {
    const sidebar = this.getSidebar(event.type);
    if (sidebar) {
      sidebar.closing = true;
    }
    setTimeout(() => {
      this.sidebarStack = this.sidebarStack.filter(
        (sidebar) => sidebar.sidebarType !== event.type
      );
    }, 200);

    this.closeTrigger.next(event);
  }

  onSidebarClose<T extends SidebarType>(
    sidebarType: T
  ): Observable<SidebarCloseEvent<T>> {
    return this.closeTrigger
      .asObservable()
      .pipe(filter((event) => event.type === sidebarType));
  }
}

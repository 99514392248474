import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { Route } from '../menu/routes';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  tabs: ReplaySubject<Route[]> = new ReplaySubject();
  currentTabs: Route[] = [];
  tabsOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  tabsOpenCurrent: boolean = false;
  activeRoute: Subject<string[]> = new Subject();
  activeRouteCurrent: string[] = [];
  releaseNotesShown: boolean = false;

  constructor(private router: Router) {
    this.tabs.subscribe((tabs) => {
      localStorage.setItem("TABS", JSON.stringify(tabs));
      this.currentTabs = tabs;
    });

    this.tabsOpen.subscribe((tabsOpenCurrent) => {
      this.tabsOpenCurrent = tabsOpenCurrent;
      if (!this.tabsOpenCurrent) {
        this.activeRoute.next([]);
      }
    });

    this.activeRoute.subscribe(
      (activeRouteCurrent) => (this.activeRouteCurrent = activeRouteCurrent)
    );
  }

  tabsToggle() {
    this.tabsOpen.next(!this.tabsOpenCurrent);
  }

  setTabs(routes: Route[]) {
    this.currentTabs = routes;
    this.tabs.next(this.currentTabs);
  }

  addTab(route: Route) {
    this.currentTabs.push(route);
    this.tabs.next(this.currentTabs);
  }

  addTabAndOpen(route: Route) {
    this.addTab(route);
    this.activeRoute.next(route.route);
    this.router.navigate([{ outlets: { tabs: route.route } }]);
    this.tabsOpen.next(true);
  }

  closeTab(route: string[]) {
    const index = this.currentTabs.findIndex(
      (routeA) => routeA.route === route
    );
    this.currentTabs.splice(index, 1);
    this.tabs.next(this.currentTabs);
  }

  closeCurrentTab() {
    this.closeTab(this.activeRouteCurrent);
  }
}

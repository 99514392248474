<div
  class="fixed mobileLandscape:inset-0 top-0 left-0 h-full overflow-x-hidden overflow-y-auto flex justify-end w-full transition ease-in-out duration-300 z-30"
  data-cy="sidebarModal">
  <div class="fixed mr-[500px] inset-0 bg-blackTranslucent" data-cy="sidebarModalBackground"
    (click)="handleClickOutside($event)"></div>
  <div
    class="sm:w-[500px] bg-white mobileLandscape:ml-auto h-full overflow-y-auto transition ease-in-out duration-300 delay-50"
    data-cy="innerSidebar" ngClass="sidebarStore.isSidebarOpen(props.type) ? 'translate-x-0' : 'translate-x-full'">
    <div class="flex flex-col h-full w-full items-center">
      <div *ngIf="loadedComponent"
        class="flex flex-col gap-2.5 h-fit flex-1 w-full overflow-y-auto mobileLandscape:px-10 px-5 pt-7 mobileLandscape:pb-10 pb-4">
        <div class="w-full flex items-center justify-between">
          <h2 class="text-2xl font-bold text-gray-800 uppercase">{{ getTitle() }}</h2>
          <button class="button-animation px-3 py-1.5 rounded-md bg-gray-200 text-gray-700 text-sm flex items-center gap-1.5" (click)="this.sidebarService.closeSidebar({ type: this.type, action: 'cancel' })"
           data-cy="closeSidebarButton">
           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="size-4">
            <path d="M5.28 4.22a.75.75 0 0 0-1.06 1.06L6.94 8l-2.72 2.72a.75.75 0 1 0 1.06 1.06L8 9.06l2.72 2.72a.75.75 0 1 0 1.06-1.06L9.06 8l2.72-2.72a.75.75 0 0 0-1.06-1.06L8 6.94 5.28 4.22Z" />
          </svg>
          <span class="font-semibold">
            Schließen
          </span>
        </button>
        </div>
        <ng-container *ngComponentOutlet="loadedComponent; injector: loadedComponentInjector || undefined">
        </ng-container>
      </div>
    </div>
  </div>
</div>

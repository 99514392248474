import {
  APP_INITIALIZER,
  ErrorHandler,
  importProvidersFrom,
} from '@angular/core';
import 'reflect-metadata';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserModule } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ApmErrorHandler, ApmModule } from '@elastic/apm-rum-angular';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { environment } from '../environments/environment';
import { routes } from './app.routes';
import { ArticleService } from './services/article.service';
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { AuthService } from './services/auth.service';
import { HhHttpService } from './services/hh-http.service';

import { ApplicationConfig } from '@angular/core';
import { provideRouter, withInMemoryScrolling } from '@angular/router';

const initializeKeycloak = (keycloakSrv: KeycloakService) => {
  return () =>
    keycloakSrv.init({
      config: {
        url: environment.keycloakRoot,
        realm: 'hhelp',
        clientId: 'hhelp-frontend',
      },
      initOptions: {
        checkLoginIframe: false,
      },
      loadUserProfileAtStartUp: false,
    });
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withInMemoryScrolling({
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled',
      }),
    ),
    importProvidersFrom(
      ApmModule,
      BrowserModule,
      FormsModule,
      GoogleMapsModule,
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: environment.production,
        // Register the ServiceWorker as soon as the app is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000',
      }),
      KeycloakAngularModule,
    ),
    HhHttpService,
    ArticleService,
    AuthService,
    {
      provide: ErrorHandler,
      useClass: ApmErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
  ],
};

<div (window:keydown)="onSearchToggle($event)" (window:keydown.escape)="searchVisible = false"
  (window:keydown.enter)="onOpenFocussed()" (window:keydown.arrowdown)="focusedResultIndex = focusedResultIndex + 1"
  (window:keydown.ArrowUp)="focusedResultIndex = focusedResultIndex - 1" class="flex overflow-hidden">
</div>
<div *ngIf="searchVisible" class="md:absolute md:inset-0 flex items-center justify-center md:z-50 h-full">
  <div [@fade] (click)="onSearchClose()" class="bg-white opacity-50 inset-0 absolute hidden md:block"></div>
  <div [@zoom] class="
      my-4
      md:my-0
      flex flex-col
      md:absolute md:top-1/3
      bg-gradient-to-r
      from-gray-700
      to-gray-600
      rounded-md
      overflow-hidden
      shadow-lg
      md:w-120
      lg:w-148
      max-h-96
      divide-y divide-gray-500
    ">
    <input #searchBox [(ngModel)]="q" (ngModelChange)="onSearch()" type="text" class="
        rounded-md
        focus:ring-0
        px-3
        lg:px-5
        py-2
        lg:py-3
        text-xl text-white
        font-bold
        z-40
        border-0
        bg-gradient-to-r
        from-gray-700
        to-gray-600
      " placeholder="Suchen..." />
    <div *ngIf="!loading && results && results.length > 0" class="max-h-96 overflow-y-auto">
      <div (click)="onNavigate(item)" *ngFor="let item of results; let i = index;" class="
          flex
          justify-between
          items-center
          text-white
          hover:bg-gray-800
          focus:bg-gray-800
          cursor-pointer
        " [ngClass]="focusedResultIndex === i ? 'bg-gray-800 shadow-sm' : ''">
        <div class="flex flex-col p-2">
          <span class="text-sm md:text-lg font-medium text-gray-100">{{
            item.text
            }}</span>
          <span class="text-xs md:text-sm text-gray-200">{{
            item.subText
            }}</span>
        </div>
        <div class="text-xs p-2">{{ item.type }}</div>
      </div>
    </div>
    <div *ngIf="loading" class="w-full">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        style="margin: auto; display: block" width="200px" height="200px" viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid">
        <g transform="translate(20 50)">
          <circle cx="0" cy="0" r="6" fill="#ffffff">
            <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline"
              keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite">
            </animateTransform>
          </circle>
        </g>
        <g transform="translate(40 50)">
          <circle cx="0" cy="0" r="6" fill="#ffffff">
            <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline"
              keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite">
            </animateTransform>
          </circle>
        </g>
        <g transform="translate(60 50)">
          <circle cx="0" cy="0" r="6" fill="#ffffff">
            <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline"
              keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite">
            </animateTransform>
          </circle>
        </g>
        <g transform="translate(80 50)">
          <circle cx="0" cy="0" r="6" fill="#ffffff">
            <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline"
              keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite">
            </animateTransform>
          </circle>
        </g>
      </svg>
    </div>
  </div>
</div>

import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { MenuService } from '../../services/menu.service';
import { Route } from '../routes';

@Component({
    selector: 'app-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, NgClass]
})
export class TabsComponent implements OnInit {
  activeRoute: string[] = [];
  secondaryRoutes: Route[] = [];

  constructor(
    private menuSrv: MenuService,
    private router: Router,
    private route: ActivatedRoute,
    public authSrv: AuthService,
  ) { }

  onTabsOpen() {
    this.menuSrv.tabsOpen.next(true);
  }

  onTabsChange(route: Route) {
    this.router
      .navigate([{ outlets: { tabs: route.route } }])
      .then((navigated) => {
        if (!navigated) {
          this.menuSrv.tabsToggle();
        } else {
          this.menuSrv.activeRoute.next(route.route);
          this.menuSrv.tabsOpen.next(true);
        }
      });
  }

  onCloseTab(route: string[]) {
    this.menuSrv.closeTab(route);
  }

  ngOnInit(): void {

    this.menuSrv.tabs.subscribe((tabs) => {
      this.secondaryRoutes = tabs;
    });

    this.menuSrv.activeRoute.subscribe(
      (activeRoute) => (this.activeRoute = activeRoute)
    );
  }

}

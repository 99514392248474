import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';

export const routes: Routes = [
  {
    path: 'articles',
    loadComponent: () => import('./routing/show-articles/show-articles.component').then(c => c.ShowArticlesComponent),
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadComponent: () => import('./article/article-empty/article-empty.component').then(c => c.ArticleEmptyComponent),
        outlet: 'article',
      },
      {
        path: 'edit',
        loadComponent: () => import('./article/article-edit/article-edit.component').then(c => c.ArticleEditComponent),
        outlet: 'article',
      },
      {
        path: 'edit/:articleId',
        loadComponent: () => import('./article/article-edit/article-edit.component').then(c => c.ArticleEditComponent),
        outlet: 'article',
      },
      {
        path: ':articleId',
        loadComponent: () => import('./article/article/article.component').then(c => c.ArticleComponent),
        outlet: 'article',
      },
    ],
  },
  { path: 'register',
    loadComponent: () => import('./routing/register/register.component').then(c => c.RegisterComponent),
    canActivate: [AuthGuard]
  },
  {
    path: 'boxInventory',
    loadComponent: () => import('./box/box-inventory/box-inventory.component').then(c => c.BoxInventoryComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'blocks',
    loadComponent: () => import('./routing/routing-blocks/routing-blocks.component').then(m => m.RoutingBlocksComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'myOrders',
    loadComponent: () => import('./routing/my-orders/my-orders.component').then(c => c.MyOrdersComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'groups',
    loadComponent: () => import('./routing/show-groups/show-groups.component').then(c => c.ShowGroupsComponent),
    canActivate: [AuthGuard],
    children: [
      {
        path: ':groupId',
        loadComponent: () => import('./group/group/group.component').then(c => c.GroupComponent),
        outlet: 'group',
      },
    ],
  },
  {
    path: 'boxes/:boxId',
    loadComponent: () => import('./routing/routing-box/routing-box.component').then(m => m.RoutingBoxComponent),
    canActivate: [AuthGuard],
    outlet: 'tabs',
  },
  {
    path: 'pallet-inventory/:palletId',
    canActivate: [AuthGuard],
    loadComponent: () => import('./routing/routing-pallet-inventory/routing-pallet-inventory.component').then(m => m.RoutingPalletInventoryComponent),
  },
  {
    path: 'hashtag-row-inventory/:hashtagRowId',
    canActivate: [AuthGuard],
    loadComponent: () => import('./routing/routing-hashtag-row-inventory/routing-hashtag-row-inventory.component').then(m => m.RoutingHashtagRowInventoryComponent),
  },
  {
    path: 'pallets/:palletId',
    loadComponent: () => import('./routing/routing-pallet/routing-pallet.component').then(m => m.RoutingPalletComponent),
    canActivate: [AuthGuard],
    outlet: 'tabs',
  },
  {
    path: 'orders/:orderId',
    loadComponent: () => import('./routing/routing-order/routing-order.component').then(m => m.RoutingOrderComponent),
    canActivate: [AuthGuard],
    outlet: 'tabs',
  },
  {
    path: 'parts/:partId',
    loadComponent: () => import('./routing/routing-part/routing-part.component').then(m => m.RoutingPartComponent),
    canActivate: [AuthGuard],
    outlet: 'tabs',
  },
  {
    path: 'camps/:campId',
    loadComponent: () => import('./routing/routing-camp/routing-camp.component').then(m => m.RoutingCampComponent),
    canActivate: [AuthGuard],
    outlet: 'tabs',
  },
  {
    path: 'persons/:personId',
    loadComponent: () => import('./routing/routing-person/routing-person.component').then(m => m.RoutingPersonComponent),
    canActivate: [AuthGuard],
    outlet: 'tabs',
  },
  {
    path: 'groups/:groupId',
    loadComponent: () => import('./routing/routing-group/routing-group.component').then(m => m.RoutingGroupComponent),
    canActivate: [AuthGuard],
    outlet: 'tabs',
  },
  {
    path: 'hashtagRows/:hashtagRowId',
    loadComponent: () => import('./routing/routing-hashtag-row/routing-hashtag-row.component').then(m => m.RoutingHashtagRowComponent),
    canActivate: [AuthGuard],
    outlet: 'tabs',
  },
  {
    path: 'articles/:articleId',
    loadComponent: () => import('./routing/routing-article/routing-article.component').then(m => m.RoutingArticleComponent),
    canActivate: [AuthGuard],
    outlet: 'tabs',
  },
  {
    path: 'orders',
    loadComponent: () => import('./routing/show-orders/show-orders.component').then(c => c.ShowOrdersComponent),
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadComponent: () => import('./order/order-empty/order-empty.component').then(c => c.OrderEmptyComponent),
        outlet: 'order',
      },
      {
        path: 'compose',
        loadComponent: () => import('./order/compose-order/compose-order.component').then(c => c.ComposeOrderComponent),
        outlet: 'order',
      },
      {
        path: 'pack/:orderId',
        loadComponent: () => import('./order/order-pack/order-pack.component').then(c => c.OrderPackComponent),
        outlet: 'order',
      },
      {
        path: 'compose/:orderId',
        loadComponent: () => import('./order/compose-order/compose-order.component').then(c => c.ComposeOrderComponent),
        outlet: 'order',
      },
      {
        path: ':orderId',
        loadComponent: () => import('./order/order/order.component').then(c => c.OrderComponent),
        outlet: 'order',
      },
    ],
  },
  {
    path: 'ordersExternal/compose',
    loadComponent: () => import('./order/compose-order-external/compose-order-external.component').then(c => c.ComposeOrderExternalComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'ordersExternal/compose/:campId/:customerId',
    loadComponent: () => import('./order/compose-order-external/compose-order-external.component').then(c => c.ComposeOrderExternalComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'ordersExternal/:orderId',
    loadComponent: () => import('./order/order-light/order-light.component').then(c => c.OrderLightComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'orderMap',
    loadComponent: () => import('./order/orders-map/orders-map.component').then(c => c.OrdersMapComponent),
  },
  {
    path: 'camps',
    loadComponent: () => import('./routing/show-camps/show-camps.component').then(c => c.ShowCampsComponent),
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadComponent: () => import('./camp/camp-empty/camp-empty.component').then(c => c.CampEmptyComponent),
        outlet: 'camp',
      },
      {
        path: 'edit',
        loadComponent: () => import('./camp/camp-edit/camp-edit.component').then(c => c.CampEditComponent),
        outlet: 'camp',
      },
      {
        path: 'edit/:campId',
        loadComponent: () => import('./camp/camp-edit/camp-edit.component').then(c => c.CampEditComponent),
        outlet: 'camp',
      },
      {
        path: ':campId',
        loadComponent: () => import('./camp/camp/camp.component').then(c => c.CampComponent),
        outlet: 'camp',
      },
    ],
  },
  {
    path: 'persons',
    loadComponent: () => import('./routing/show-persons/show-persons.component').then(c => c.ShowPersonsComponent),
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadComponent: () => import('./person/person-empty/person-empty.component').then(c => c.PersonEmptyComponent),
        outlet: 'person',
      },
      {
        path: 'new',
        loadComponent: () => import('./person/person-edit/person-edit.component').then(c => c.PersonEditComponent),
        outlet: 'person',
      },
      {
        path: 'edit',
        loadComponent: () => import('./person/person-edit/person-edit.component').then(c => c.PersonEditComponent),
        outlet: 'person',
      },
      {
        path: 'edit/:personId',
        loadComponent: () => import('./person/person-edit/person-edit.component').then(c => c.PersonEditComponent),
        outlet: 'person',
      },
      {
        path: ':personId',
        loadComponent: () => import('./person/person/person.component').then(c => c.PersonComponent),
        outlet: 'person',
      },
    ],
  },
  {
    path: 'persons/new',
    loadComponent: () => import('./person/person-new/person-new.component').then(c => c.PersonNewComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'personalSettings',
    loadComponent: () => import('./routing/routing-personal-settings/routing-personal-settings.component').then(m => m.RoutingPersonalSettingsComponent),
    canActivate: [AuthGuard],
  },
  {
    path: '',
    loadComponent: () => import('./routing/routing-dashboard/routing-dashboard.component').then(m => m.RoutingDashboardComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    loadComponent: () => import('./routing/routing-dashboard-external/routing-dashboard-external.component').then(m => m.RoutingDashboardExternalComponent),
    canActivate: [AuthGuard],
  },
  {
    path: '404',
    loadComponent: () => import('./routing/routing-not-found/routing-not-found.component').then(m => m.RoutingNotFoundComponent),
  },
  {
    path: '**',
    redirectTo: '/404',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

<div (clickOutside)="additionalMenuShown = false; accountModalShown = false" class="bg-primary flex flex-col">
  <div class="
      relative
      flex
      shadow-md
      text-white
      justify-between
      print:hidden
      max-w-full
    " *ngIf="visible">
    <div (click)="onMobileMenuToggle()" class="
        flex
        md:hidden
        whitespace-nowrap
        p-4
        hover:bg-primaryDark
        transition
        duration-3000
        cursor-pointer
      ">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
      </svg>
    </div>
    <div class="text-bold ml-2 gap-2 md:flex hidden shrink-0" *ngIf="authSrv.authContext?.person">
      <ng-container *ngFor="let route of routes">
        <div *ngIf="
            route.showOnTopMenu &&
            ((route.rightText && authSrv.hasRight(route.rightText)) || !route.rightText)
          " class="
            whitespace-nowrap
            flex
            transition
            duration-3000
            cursor-pointer
            space-x-2
            items-center
            font-medium
            my-2 rounded-md
            select-none
          " [routerLink]="route.route" [routerLinkActiveOptions]="{ exact: route.exact }"
          [routerLinkActive]="!route.logo ? 'bg-primaryDark' : ''"
          [ngClass]="{ 'font-bold': route.bold, 'py-1 hover:bg-primaryDark': !route.logo, 'shrink-0': route.logo}">
          <img *ngIf="route.logo" class="h-10 w-10" src="/assets/img/logo_menu.png" alt="Logo" />
          <span class="px-3" *ngIf="!route.logo">{{ route.text }}</span>
        </div>
      </ng-container>
      <ng-container *ngIf="filterRoutes(additionalRoutes).length > 0">
        <div (click)="onAdditionalMenuToggle()" class="
            whitespace-nowrap
            flex
            hover:bg-primaryDark
            transition
            font-medium
            duration-3000
            cursor-pointer
            items-center
            px-4
            my-2
            mr-2
            rounded-md
            select-none
            gap-1
          ">
          <span>Sonstiges</span>
          <svg [ngClass]="additionalMenuShown ? 'rotate-180' : ''" xmlns="http://www.w3.org/2000/svg" fill="none"
            viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
          </svg>

          <div *ngIf="additionalMenuShown" class="
              absolute
              top-14
              mt-2
              -ml-4
              w-56
              rounded-md
              shadow-lg
              bg-white
              z-30
              ring-1 ring-black ring-opacity-5
              divide-y divide-gray-100
              focus:outline-none
              overflow-hidden
            " role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
            <div *ngFor="let route of additionalRoutes" class="py-1 hover:bg-gray-100" role="none"
              [routerLinkActive]="'bg-gray-100 text-gray-900'">
              <a *ngIf="
                  !route.showOnTopMenu &&
                  ((route.rightText && authSrv.hasRight(route.rightText)) ||
                    !route.rightText)
                " [routerLink]="route.route" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem"
                tabindex="-1" id="menu-item-0">{{ route.text }}</a>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="flex max-w-full overflow-x-auto">
      <div *ngIf="envName !== 'prod'" class="bg-white select-none px-3 py-1.5 rounded-md text-orange-600 self-center font-bold flex items-center gap-1">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="m6.75 7.5 3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0 0 21 18V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v12a2.25 2.25 0 0 0 2.25 2.25Z" />
        </svg>
        <span>{{envName}}</span>
      </div>
      <app-tabs class="md:flex lg:ml-2 hidden items-center max-w-full overflow-x-auto"></app-tabs>
      <div class="relative flex items-center">
        <div (click)="onSearch()" class="
            whitespace-nowrap
            flex
            p-2
            transition
            duration-3000
            cursor-pointer
            select-none
          ">
          <!-- search -->
          <button class="bg-primary400 duration-150 py-1.5 px-3 rounded-md flex items-center gap-2 button-animation">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
            <span class="hidden lg:inline font-medium">Suchen</span>
            <div class="ml-2 border border-white rounded-md gap-1 px-1.5 md:flex hidden">
              {{searchPlaceholder}}
            </div>
          </button>
        </div>
        <div *ngIf="authSrv.authContext?.person" (click)="accountModalShown = !accountModalShown" routerLinkActive="bg-primaryDark"
          class="
            font-bold
            bg-white
            hover:bg-primary100
            text-primary
            px-2
            select-none
            py-2
            my-2
            mr-2
            rounded-full
            transition
            duration-3000
            cursor-pointer
          ">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
          </svg>

        </div>
      </div>
      <div *ngIf="accountModalShown" class="
            origin-top-right
            absolute
            right-0
            top-14
            mt-2
            w-56
            z-30
            rounded-md
            shadow-lg
            bg-white
            ring-1 ring-black ring-opacity-5
            divide-y divide-gray-100
            focus:outline-none
          " role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
        <div class="px-4 py-3" role="none">
          <p class="text-sm text-gray-900 font-bold" role="none">{{authSrv.authContext?.person?.firstName}} {{authSrv.authContext?.person?.lastName}}
          </p>
          <p class="text-xs text-gray-700 truncate" role="none">
            {{ authSrv.authContext?.person?.eMail_1 }}
          </p>
        </div>
        <div class="py-1 hover:bg-gray-100" role="none">
          <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
          <a [routerLink]="['/personalSettings']" (click)="accountModalShown = false"
            class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">Einstellungen</a>
        </div>
        <div class="py-1 hover:bg-gray-100" role="none">
          <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
          <button (click)="authSrv.redirectToAccountManagement()" class="text-gray-700 block px-4 py-2 text-sm cursor-pointer" role="menuitem"
            tabindex="-1" id="menu-item-0">mein Konto</button>
        </div>
        <div class="py-1 hover:bg-gray-100" role="none">
          <button (click)="onLogout(); accountModalShown = false" class="
                text-gray-700
                hover:bg-gray-100
                block
                w-full
                text-left
                px-4
                py-2
                text-sm
              " role="menuitem" tabindex="-1" id="menu-item-3">
            Abmelden
          </button>
        </div>
      </div>
    </div>

  </div>
  <div *ngIf="mobileMenuShown" class="
      flex flex-col
      md:hidden
      items-center
      absolute
      h-screenExceptMenu
      mt-14
      bg-gray-50
      w-full
      z-50
    ">
    <button *ngFor="let route of routes" class="
        active:bg-gray-200
        text-gray-700
        w-full
        px-4
        animate-slideInFromLeft
      " [routerLink]="route.route" [routerLinkActiveOptions]="{ exact: route.exact }" routerLinkActive="bg-gray-200"
      [ngClass]="{ 'font-bold': route.bold }" (click)="mobileMenuShown = false">
      <div class="py-2" *ngIf="
          (route.rightText && authSrv.hasRight(route.rightText)) || !route.rightText
        ">
        <span class="font-bold uppercase">{{ route.text }}</span>
      </div>
    </button>
    <button class="text-gray-700 w-full px-4 animate-slideInFromLeft py-2" (click)="onLogout();">
      <span class="font-bold uppercase">Abmelden</span>
    </button>
  </div>
</div>

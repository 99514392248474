import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-menu-external',
  standalone: true,
  imports: [NgIf, RouterLink],
  templateUrl: './menu-external.component.html',
  styleUrl: './menu-external.component.scss'
})
export class MenuExternalComponent implements OnInit, OnDestroy {

  showBackButton = false;
  routerEventSubscription: Subscription;

  constructor(
      public authSrv: AuthService,
      private router: Router,
    ) {
    }


  ngOnInit(): void {
    this.showBackButton = this.router.url !== '/';
    this.routerEventSubscription = this.router.events.subscribe((val) => {
      this.showBackButton = this.router.url !== '/';
    });
  }

  ngOnDestroy(): void {
    this.routerEventSubscription.unsubscribe();
  }
}

// open-sidebars.component.ts
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { SidebarService } from '../../services/sidebar.service';
import { SidebarComponent } from '../sidebar/sidebar.component';

@Component({
  standalone: true,
  selector: 'app-open-sidebars',
  imports: [
    CommonModule,
    SidebarComponent,
  ],
  template: `
    <ng-container *ngFor="let sidebar of sidebarService.getAllSidebars()">
      <app-sidebar [type]="sidebar.sidebarType"></app-sidebar>
    </ng-container>
  `,
})
export class OpenSidebarsComponent {
  constructor(public sidebarService: SidebarService) {}
}

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ArticleFullSizeDto, CreateArticleDto, UpdateArticleDto } from '../../../../shared/dtos';
import { TransformerWrapper } from '../../../../shared/src/transformer';
import { HhHttpService } from './hh-http.service';

@Injectable({
  providedIn: 'root',
})
export class ArticleService {
  constructor(private httpSrv: HhHttpService) {}

  updateTrigger: Subject<void> = new Subject<void>();

  findAll(): Observable<ArticleFullSizeDto[]> {
    return this.httpSrv.get<ArticleFullSizeDto[]>('articles');
  }

  findById(id: number) {
    return this.httpSrv.get<ArticleFullSizeDto>(`articles/${id}`);
  }

  findByName(
    name: string,
    options: {
      showOnlyAddable: boolean;
      showOnlyShoppable: boolean;
      showOnlyNotAddable: boolean;
      showOnlyNotShoppable: boolean;
      showDisabled: boolean;
    },
  ) {

    const query = new URLSearchParams();
    query.set('showOnlyAddable', options.showOnlyAddable ? '1' : '0');
    query.set('showOnlyShoppable', options.showOnlyShoppable ? '1' : '0');
    query.set('showOnlyNotAddable', options.showOnlyNotAddable ? '1' : '0');
    query.set('showOnlyNotShoppable', options.showOnlyNotShoppable ? '1' : '0');
    query.set('showDisabled', options.showDisabled ? '1' : '0');

    return this.httpSrv.get<ArticleFullSizeDto[]>(
      `articles/q/${name}?${query.toString()}`,
    );
  }

  getSentItems() {
    return this.httpSrv.get<{ sum: number; sumPretty: string }>(
      `articles/sent`,
    );
  }

  update(articleId: number, article: UpdateArticleDto) {
    return this.httpSrv
      .patch<ArticleFullSizeDto>(
        `articles/${articleId}`,
        TransformerWrapper.plainToInstanceExclude(UpdateArticleDto, article),
      )
      .pipe(
        map((elem) =>
          TransformerWrapper.plainToInstanceExclude(ArticleFullSizeDto, elem),
        ),
      );
  }

  create(article: CreateArticleDto) {
    return this.httpSrv
      .post<ArticleFullSizeDto>(`articles`, TransformerWrapper.plainToInstanceExclude(CreateArticleDto, article))
      .pipe(map((elem) => Object.assign(new ArticleFullSizeDto(), elem)));
  }
}

import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { ApmService } from '@elastic/apm-rum-angular';
import { KeycloakService } from 'keycloak-angular';
import { filter } from 'rxjs';
import pkg from '../../package.json';
import { environment } from '../environments/environment';
import { AlertComponent } from './alert/alert.component';
import { OpenSidebarsComponent } from "./generic/open-sidebars/open-sidebars.component";
import { VERSION } from './localStorageConsts';
import { MenuExternalComponent } from "./menu-external/menu-external.component";
import { MenuComponent } from './menu/menu.component';
import { ReleaseNotesComponent } from './menu/release-notes/release-notes.component';
import { SearchComponent } from './search/search.component';
import { AlertService } from './services/alert.service';
import { AuthService } from './services/auth.service';
import { MenuService } from './services/menu.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [
    NgIf,
    MenuComponent,
    SearchComponent,
    RouterOutlet,
    AlertComponent,
    ReleaseNotesComponent,
    MenuExternalComponent,
    OpenSidebarsComponent
],
})
export class AppComponent implements OnInit {
  constructor(
    public authSrv: AuthService,
    private alertSrv: AlertService,
    public menuSrv: MenuService,
    private router: Router,
    private keycloakSrv: KeycloakService,
    swUpdate: SwUpdate,
    private apmService: ApmService,
  ) {
    const apm = this.apmService.init({
      serviceName: 'logistic_frontend_' + environment.envName,
      serverUrl: 'https://apm.hanseatic-help.org',
    });

    swUpdate.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .subscribe(evt => {
        console.log(evt);
        this.swUpdatePromptOpen = true;
      });
  }

  tabsOpen: boolean = false;
  swUpdatePromptOpen: boolean = false;
  searchVisible: boolean = false;
  appVersion: string = pkg.version;
  alertTimeout: ReturnType<typeof setTimeout> | null = null;

  alert: { text: string; linkText: string } = {text: '', linkText: ''};

  onTabMinimize() {
    this.menuSrv.tabsOpen.next(false);
  }

  onTabClose() {
    this.menuSrv.closeCurrentTab();
    this.onTabMinimize();
  }

  onSearch() {
    this.searchVisible = true;
  }

  onUpdate() {
    window.location.reload();
  }

  showReleaseNotes() {
    const localStorageVersion = localStorage.getItem(VERSION);

    if (!this.authSrv.hasRight('maintain orders for own') && this.appVersion !== localStorageVersion && this.appVersion !== 'TEST') {
      this.menuSrv.releaseNotesShown = true;
      localStorage.setItem(VERSION, this.appVersion);
    }
  }

  async ngOnInit() {

    if (window.localStorage.getItem('access_token')) {
      this.authSrv.setToken(window.localStorage.getItem('access_token') + '');
      await this.authSrv.updateMyRights();
    } else {
      await this.authSrv.autoLogin();
    }

    if (localStorage.getItem("TABS")) {
      this.menuSrv.setTabs(JSON.parse(localStorage.getItem("TABS") + ''));
    }

    this.menuSrv.tabsOpen.subscribe((tabsOpen) => (this.tabsOpen = tabsOpen));

    this.alertSrv.alert.asObservable().subscribe((alert) => {
      this.alert = alert;
      this.alertTimeout && clearTimeout(this.alertTimeout);
      this.alertTimeout = setTimeout(() => {
        this.alertSrv.alert.next({text: '', linkText: ''});
      }, 6000);
    });
  }
}

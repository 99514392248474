<div class="max-w-5xl mx-auto my-2">
  <div class="text-sm mx-2 bg-white text-gray-600 font-semibold flex items-center justify-between gap-2.5 ">
    <a *ngIf="showBackButton" routerLink="/"
      class="cursor-pointer whitespace-nowrap self-start text-sm font-base text-primary hover:underline flex items-center gap-2.5">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="size-4">
        <path fill-rule="evenodd"
          d="M14 8a.75.75 0 0 1-.75.75H4.56l3.22 3.22a.75.75 0 1 1-1.06 1.06l-4.5-4.5a.75.75 0 0 1 0-1.06l4.5-4.5a.75.75 0 0 1 1.06 1.06L4.56 7.25h8.69A.75.75 0 0 1 14 8Z"
          clip-rule="evenodd" />
      </svg>
      <span>Zurück zur Übersicht</span>
    </a>
    <span *ngIf="!showBackButton">&nbsp;</span>
    <div class="flex gap-2.5">
      <button class="hover:underline" (click)="authSrv.redirectToAccountManagement()">Account</button>
      <button class="hover:underline" (click)="authSrv.logout()">Abmelden</button>
    </div>
  </div>
</div>
